<template>
    <div class="gdoc-view">
        <v-progress-circular
            indeterminate
            color="primary"
            class="preloader"
            :style="iframePreloaderStyles"
        ></v-progress-circular>
        <iframe
            v-if="item"
            :src="iframeUrl"
            width="100%"
            height="100%"
            :style="iframeLoadableStyles"
            class="gdoc-embed"
            v-on:load="iframeOnLoad"
        ></iframe>
    </div>
</template>

<script>
import mediaViewMixin from '@apps/media/mixins/mediaViewMixin'

export default {
    name: 'GdocView',
    mixins: [ mediaViewMixin ],
    computed: {
        encodedSrc() {
            return encodeURI(this.item.url)
        },
        iframeUrl() {
            return `https://docs.google.com/viewer?url=${this.encodedSrc}&embedded=true`
        }
    }
}
</script>

<style lang=scss>
    .gdoc-view {
        height: 99%;

        .gdoc-embed, .preloader {
            transition: all .3s;
        }

        .preloader {
            position: absolute;
            width: 100%;
            left: calc(50% - 32px);
            top: calc(50% - 32px);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>
